import NavBar from './NavBar';

import headerStyles from './Header.module.css';

function Header() {
  // const shoppingCartCounter = 0;
  return (
    <div className={headerStyles.header}>
      {/*      <nav className={appStyles.container}>
        <Link to="/" className="navbar-brand">
          <img src={logo} height="100px" alt="Peludos" />
        </Link>
        <form className={headerStyles.formSearch}>
          <Categories />
          <SearchBox />
        </form>
        <Link to="/shopping-cart" className={headerStyles.shoppingCart}>
          <FaShoppingCart size={40} />
          {shoppingCartCounter > 0 && <label className={headerStyles.shoppingCartCounter}>
            {shoppingCartCounter}
          </label>}
        </Link>
      </nav> Descomentar para agregar zona de busqueda */}
      <NavBar />
    </div>
  );
}

export default Header;
