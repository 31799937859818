import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/delantal2.jpg';
import Item2 from '../../static/images/delantal3.jpg';
import Item3 from '../../static/images/delantal4.jpg';
import Item4 from '../../static/images/delantal5.jpg';

function Aprons() {
  const title = (
    <>
      Delantales.
    </>
  );
  const aprons = [Item1, Item2, Item3, Item4];

  return (
    <CustomCarousel title={title} id="aprons" items={aprons} />
  );
}

export default Aprons;
