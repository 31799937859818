import { GiPrimitiveNecklace } from 'react-icons/gi';

import AccessoriesCarousel from './AccessoriesCarousel';

import Item1 from '../../static/images/collar1.jpg';
import Item2 from '../../static/images/collar2.jpg';
import Item3 from '../../static/images/collar3.jpg';
import Item4 from '../../static/images/collar4.jpg';
import Item5 from '../../static/images/collar5.jpg';
import Item6 from '../../static/images/collar6.jpg';
import Item7 from '../../static/images/collar7.jpg';
import Item8 from '../../static/images/collar8.jpg';
import Item9 from '../../static/images/collar9.jpg';

function Collares() {
  const title = (
    <>
      <GiPrimitiveNecklace />
      Collares.
    </>
  );
  const collares = [Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8, Item9];

  return (
    <AccessoriesCarousel title={title} id="collares" items={collares} />
  );
}

export default Collares;
