import {
  FaFacebook, FaInstagram, FaTiktok,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import appStyles from '../App.module.css';

type SocialIconsArgs = {
  containerClassName: string
  iconClassName: string
};

function SocialIcons({ containerClassName, iconClassName }: SocialIconsArgs) {
  return (
    <div className={containerClassName}>
      <a
        className={iconClassName}
        href="https://www.instagram.com/latiendadepeludos/"
        rel="noreferrer"
        data-tip
        data-for="instagramTip"
        target="_blank"
      >
        <FaInstagram />
      </a>

      <a
        className={iconClassName}
        href="https://www.facebook.com/latiendadepeludos"
        rel="noreferrer"
        data-tip
        data-for="facebookTip"
        target="_blank"
      >
        <FaFacebook />
      </a>

      <a
        className={iconClassName}
        href="https://www.tiktok.com/@latiendadepeludos/"
        rel="noreferrer"
        data-tip
        data-for="tiktokTip"
        target="_blank"
      >
        <FaTiktok />
      </a>
      <ReactTooltip className={appStyles.tooltip} id="instagramTip" place="bottom" effect="solid" textColor="white" backgroundColor="#009F97">
        Siguenos en Instagram.
      </ReactTooltip>
      <ReactTooltip className={appStyles.tooltip} id="facebookTip" place="bottom" effect="solid" textColor="white" backgroundColor="#009F97">
        Siguenos en Facebook.
      </ReactTooltip>
      <ReactTooltip className={appStyles.tooltip} id="tiktokTip" place="bottom" effect="solid" textColor="white" backgroundColor="#009F97">
        Siguenos en TikTok.
      </ReactTooltip>
    </div>
  );
}
export default SocialIcons;
