import Carousel from '../Carousel';
import Card from '../Card';

type CustomCarouselArgs = {
  items: string[],
  title: string | JSX.Element,
  id: string,
};

function CustomCarousel({ title, items, id }: CustomCarouselArgs) {
  return (
    <Carousel title={title} id={id}>
      {items.map((item) => (
        <Card source={item} />
      ))}
    </Carousel>
  );
}

export default CustomCarousel;
