import { FaQuestionCircle } from 'react-icons/fa';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/placa1.jpeg';
import Item2 from '../../static/images/placa2.jpg';
import Item3 from '../../static/images/placa3.jpg';
import Item4 from '../../static/images/placa4.jpg';
import Item5 from '../../static/images/placa5.jpg';
import Item6 from '../../static/images/placa6.jpg';

function Plates() {
  const title = (
    <>
      <FaQuestionCircle />
      Placas.
    </>
  );
  const plates = [Item1, Item2, Item3, Item4, Item5, Item6];

  return (
    <CustomCarousel title={title} id="plates" items={plates} />
  );
}

export default Plates;
