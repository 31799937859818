import { GiPillow } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item2 from '../../static/images/cojin2.jpeg';
import Item3 from '../../static/images/cojin3.jpeg';
import Item5 from '../../static/images/cojin5.jpeg';
import Item6 from '../../static/images/cojin6.jpeg';
import Item7 from '../../static/images/cojin7.jpeg';
import Item8 from '../../static/images/cojin8.jpeg';
import Item9 from '../../static/images/cojin9.jpeg';
import Item12 from '../../static/images/cojin12.jpg';
import Item13 from '../../static/images/cojin13.jpg';
import Item14 from '../../static/images/cojin14.jpg';
import Item15 from '../../static/images/cojin15.jpg';
import Item16 from '../../static/images/cojin16.jpg';
import Item17 from '../../static/images/cojin17.jpg';
import Item18 from '../../static/images/cojin18.jpg';
import Item19 from '../../static/images/cojin19.jpg';
import Item20 from '../../static/images/cojin20.jpg';

function Cushions() {
  const title = (
    <>
      <GiPillow />
      Cojines.
    </>
  );
  const cushions = [
    Item2,
    Item3,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item12,
    Item13,
    Item14,
    Item15,
    Item16,
    Item17,
    Item18,
    Item19,
    Item20,
  ];

  return (
    <CustomCarousel title={title} id="cushions" items={cushions} />
  );
}

export default Cushions;
