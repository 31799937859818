import { LazyLoadComponent } from 'react-lazy-load-image-component';

import Carousel from '../Carousel';
import Card from '../Card';

type AccessoriesCarouselArgs = {
  items: string[],
  title: string | JSX.Element,
  id: string,
};

function AccessoriesCarousel({ title, items, id }: AccessoriesCarouselArgs) {
  return (
    <LazyLoadComponent>
      <Carousel title={title} id={id}>
        {items.map((item) => (
          <Card source={item} />
        ))}
      </Carousel>
    </LazyLoadComponent>
  );
}

export default AccessoriesCarousel;
