import ItemsPanel from '../ItemsPanel';

import Kerchiefs from './Kerchiefs';
import Beds from './Beds';
import TowelsAndBlankets from './TowelsAndBlankets';
import Collares from './Collares';

function Accessories() {
  return (
    <ItemsPanel>
      <Kerchiefs />
      <Collares />
      <TowelsAndBlankets />
      <Beds />
    </ItemsPanel>
  );
}

export default Accessories;
