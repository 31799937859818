/* eslint-disable react/jsx-props-no-spreading */
import Menu from '@mui/material/Menu';
import {
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import DropDownArgs from '../interfaces/DropDownArgs';

import appStyles from '../App.module.css';

function DropDown({
  title, children, className, popupState, position = 'left',
}: DropDownArgs) {
  return (
    <div className={className}>
      <div className={`${appStyles.link} ${appStyles.verticalAlign}`} {...bindTrigger(popupState)}>
        {title}
      </div>
      <Menu
        {...bindMenu(popupState)}
        PaperProps={{
          style: {
            backgroundColor: 'white',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
      >
        {children}
      </Menu>
    </div>
  );
}

export default DropDown;
