import FooterNav from './FooterNav';

import footerStyles from './Footer.module.css';

function Footer() {
  return (
    <div className={footerStyles.footerContainer}>
      {/* <PaymentMethods /> Descomentar para agregar metodos de pago */}
      <hr className={footerStyles.hr} />
      <FooterNav />
    </div>
  );
}

export default Footer;
