import { useState } from 'react';

import appStyles from '../App.module.css';
import CardArgs from '../interfaces/CardArgs';

interface State {
  height: number | undefined | string,
  width: number | undefined | string
}

function Card({ source, children }: CardArgs) {
  const initState = { height: 400, width: 300 };
  const [state, setState] = useState<State>(initState);

  const handleAfterLoad = () => {
    const newState = {
      height: 'auto',
      width: 'auto',
    };
    setState(newState);
  };

  return (
    <div className={appStyles.cardContainer}>
      <img
        alt="card"
        className={appStyles.card}
        src={source}
        height={state.height}
        width={state.width}
        onLoad={handleAfterLoad}
      />
      {children}
    </div>
  );
}

export default Card;
