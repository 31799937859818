import { GiBandageRoll } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/panoleta2.jpeg';
import Item2 from '../../static/images/panoleta3.jpg';
import Item3 from '../../static/images/panoleta4.jpg';
import Item4 from '../../static/images/panoleta5.jpg';

function Kerchiefs() {
  const title = (
    <>
      <GiBandageRoll />
      Pañoletas.
    </>
  );
  const kerchiefs = [Item1, Item2, Item3, Item4];

  return (
    <CustomCarousel title={title} id="kerchiefs" items={kerchiefs} />
  );
}

export default Kerchiefs;
