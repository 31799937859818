import { GiTowel } from 'react-icons/gi';

import AccessoriesCarousel from './AccessoriesCarousel';

import Item1 from '../../static/images/toalla1.jpg';
import Item2 from '../../static/images/toalla2.jpg';
import Item3 from '../../static/images/toalla3.jpg';
import Item4 from '../../static/images/toalla4.jpg';
import Item5 from '../../static/images/toalla5.jpg';
import Item6 from '../../static/images/toalla6.jpg';
import Item7 from '../../static/images/toalla7.jpg';
import Item8 from '../../static/images/toalla8.jpg';

function TowelsAndBlankets() {
  const title = (
    <>
      <GiTowel />
      Toallas y Cobijas.
    </>
  );
  const towelsAndBlankets = [Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8];

  return (
    <AccessoriesCarousel title={title} id="towelsAndBlankets" items={towelsAndBlankets} />
  );
}

export default TowelsAndBlankets;
