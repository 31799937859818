import { FaVest } from 'react-icons/fa';

import ClothesCarousel from './ClothesCarousel';

import Item2 from '../../static/images/chaleco2.jpg';
import Item3 from '../../static/images/chaleco3.jpg';
import Item4 from '../../static/images/chaleco4.jpg';
import Item5 from '../../static/images/chaleco5.jpg';
import Item6 from '../../static/images/chaleco6.jpg';
import Item7 from '../../static/images/chaleco7.jpg';
import Item8 from '../../static/images/chaleco8.jpg';
import Item9 from '../../static/images/chaleco9.jpg';
import Item10 from '../../static/images/chaleco10.jpg';

function Vests() {
  const title = (
    <>
      <FaVest />
      Chalecos.
    </>
  );

  const vests = [Item2, Item3, Item4, Item5, Item6, Item7, Item8, Item9, Item10];

  return (
    <ClothesCarousel title={title} id="vests" items={vests} />
  );
}

export default Vests;
