import Card from './Card';

import appStyles from '../App.module.css';

import angie from '../static/images/angie.png';
import buster from '../static/images/buster.png';
import carmen from '../static/images/carmen.png';
import chocolate from '../static/images/chocolate.png';
import deisy from '../static/images/deisy.png';
import fiona from '../static/images/fiona.png';
import luis from '../static/images/luis.png';
import luna from '../static/images/luna.png';
import maryuri from '../static/images/maryuri.png';
import tatiana from '../static/images/tatiana.png';

function Team() {
  return (
    <>
      <div className={appStyles.bannerImage} />
      <div className={appStyles.container}>

        <div className={appStyles.infoContainer}>
          <h5>¿Qué hacemos ?</h5>
          <p>
            Somos fabricantes, Confeccionamos ropa, accesorios y camas para nuestros peludos.
            Personalizamos pañoletas, delantales, tulas, cojines, cobijas y placas.
          </p>

          <h5>¿Cómo lo hacemos? </h5>
          <p>Ponemos amor, creatividad y compromiso en cada parte del proceso.</p>

          <h5>¿Por qué?</h5>
          <p>
            Queremos que nuestros Peludos siempre se sientan cómodos, con ropa y accesorios de
            calidad y estilo único en el mercado.
          </p>

          <h5>Nuestros servicios</h5>
          <ul>
            <li>
              Ventas al por mayor y al detal. ¡Realiza tus pedidos por nuestro WhatsApp o en
              nuestro punto físico!
            </li>
            <li>
              Servicios de maquila ¡Emprendedor! Te ayudamos hacer realidad tus proyectos,
              ofrecemos el servicio de corte y confección para tu ropa y accesorios.
            </li>
            <li>
              SPA canino y felino, con tratamiento y grooming enfocado a las necesidades de
              tu peludo y su propietario.
            </li>
          </ul>
          <b className={appStyles.boldEmptySection} />
        </div>
        <Card source={tatiana}>
          <div className={appStyles.teamLabel}>De mi cabeza surgen las ideas peludas.</div>
        </Card>
        <div className={appStyles.teamMembers}>
          <Card source={fiona}>
            <div className={appStyles.teamLabel}>
              Yo te saludo moviendo la cola cuando llegas a nuestra tienda.
            </div>
          </Card>
          <Card source={luna}>
            <div className={appStyles.teamLabel}>Superviso que todos trabajen.</div>
          </Card>
          <Card source={buster}>
            <div className={appStyles.teamLabel}>Yo modelo por Galletas.</div>
          </Card>
          <Card source={chocolate}>
            <div className={appStyles.teamLabel}>
              Hago la prueba de calidad de camas y cobijas.
            </div>
          </Card>
          <Card source={carmen}>
            <div className={appStyles.teamLabel}>
              Lidero el grupo de confección y control de calidad.
            </div>
          </Card>
          <Card source={angie}>
            <div className={appStyles.teamLabel}>
              Contesto tus mensajes y recibo con amor y alegría a tu peludo.
            </div>
          </Card>
          <Card source={luis}>
            <div className={appStyles.teamLabel}>Resalto la estética de tus peludos.</div>
          </Card>
          <Card source={maryuri}>
            <div className={appStyles.teamLabel}>Soy la dura en accesorios.</div>
          </Card>
          <Card source={deisy}>
            <div className={appStyles.teamLabel}>Soy la dura en rompevientos y camas.</div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Team;
