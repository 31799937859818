import { GiBandageRoll } from 'react-icons/gi';

import AccessoriesCarousel from './AccessoriesCarousel';

import Item1 from '../../static/images/spanoleta1.jpg';
import Item2 from '../../static/images/spanoleta2.jpg';
import Item3 from '../../static/images/spanoleta3.jpg';
import Item4 from '../../static/images/spanoleta4.jpg';
import Item5 from '../../static/images/spanoleta5.jpg';
import Item6 from '../../static/images/spanoleta6.jpg';

function Kerchiefs() {
  const title = (
    <>
      <GiBandageRoll />
      Pañoletas.
    </>
  );
  const kerchiefs = [Item1, Item2, Item3, Item4, Item5, Item6];

  return (
    <AccessoriesCarousel title={title} id="skerchiefs" items={kerchiefs} />
  );
}

export default Kerchiefs;
