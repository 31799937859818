import { GiMonclerJacket } from 'react-icons/gi';

import ClothesCarousel from './ClothesCarousel';

import Item1 from '../../static/images/chaqueta2.jpeg';
import Item2 from '../../static/images/chaqueta3.jpeg';
import Item3 from '../../static/images/chaqueta4.jpeg';
import Item4 from '../../static/images/chaqueta5.jpeg';
import Item5 from '../../static/images/chaqueta6.jpeg';
import Item7 from '../../static/images/chaqueta8.jpeg';
import Item8 from '../../static/images/chaqueta9.jpg';
import Item9 from '../../static/images/chaqueta10.jpg';
import Item10 from '../../static/images/chaqueta11.jpg';
import Item11 from '../../static/images/chaqueta12.jpg';
import Item12 from '../../static/images/chaqueta13.jpg';
import Item13 from '../../static/images/chaqueta14.jpg';
import Item14 from '../../static/images/chaqueta15.jpg';
import Item15 from '../../static/images/chaqueta16.jpg';
import Item16 from '../../static/images/chaqueta17.jpg';

function Jackets() {
  const title = (
    <>
      <GiMonclerJacket />
      Chaquetas.
    </>
  );
  const jackets = [
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
    Item12,
    Item13,
    Item14,
    Item15,
    Item16,
  ];

  return (
    <ClothesCarousel title={title} id="jackets" items={jackets} />
  );
}

export default Jackets;
