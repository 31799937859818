import { FaRegClock, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { usePopupState } from 'material-ui-popup-state/hooks';

import Dropdown from '../Dropdown';

import appStyles from '../../App.module.css';
import headerStyles from './Header.module.css';

function NavBar() {
  const isMobile = useMediaQuery({ maxWidth: 840 });
  const position = isMobile ? 'right' : 'left';
  const popupStateClothes = usePopupState({ variant: 'popover', popupId: 'clothes' });
  const popupStateAccessories = usePopupState({ variant: 'popover', popupId: 'accessories' });
  const popupStateCustom = usePopupState({ variant: 'popover', popupId: 'custom' });
  const popupStateResponsive = usePopupState({ variant: 'popover', popupId: 'responsive' });

  const handleClothesClose = () => {
    popupStateClothes.close();
    popupStateResponsive.close();
  };

  const handleAccessoriesClose = () => {
    popupStateAccessories.close();
    popupStateResponsive.close();
  };

  const handleCustomClose = () => {
    popupStateCustom.close();
    popupStateResponsive.close();
  };

  const navBarItems = [
    <Link onClick={popupStateResponsive.close} key="init" className={`${appStyles.flexLeft} ${appStyles.link} ${headerStyles.navBarItem}`} to="/">
      Inicio
    </Link>,

    <Dropdown popupState={popupStateClothes} key="clothes" title="Ropa" className={`${appStyles.flexLeft}  ${headerStyles.navBarItem}`} position={position}>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleClothesClose}
        to={{
          pathname: '/clothes',
          hash: '#jackets',
        }}
      >
        Chaquetas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem} `}
        onClick={handleClothesClose}
        to={{
          pathname: '/clothes',
          hash: '#vests',
        }}
      >
        Chalecos
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleClothesClose}
        to={{
          pathname: '/clothes',
          hash: '#sweaters',
        }}
      >
        Sacos
      </Link>
    </Dropdown>,

    <Dropdown popupState={popupStateAccessories} key="accessories" title="Accesorios" className={`${appStyles.flexLeft}  ${headerStyles.navBarItem}`} position={position}>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleAccessoriesClose}
        to={{
          pathname: '/accessories',
          hash: '#skerchiefs',
        }}
      >
        Pañoletas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem} `}
        onClick={handleAccessoriesClose}
        to={{
          pathname: '/accessories',
          hash: '#collares',
        }}
      >
        Collares
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleAccessoriesClose}
        to={{
          pathname: '/accessories',
          hash: '#towelsAndBlankets',
        }}
      >
        Toallas y Cobijas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleAccessoriesClose}
        to={{
          pathname: '/accessories',
          hash: '#beds',
        }}
      >
        Camas
      </Link>
    </Dropdown>,

    <Dropdown popupState={popupStateCustom} key="custom" title="Perzonalizados" className={`${appStyles.flexLeft}  ${headerStyles.navBarItem}`} position={position}>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#cushions',
        }}
      >
        Cojines
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#blankets',
        }}
      >
        Cobijas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#plates',
        }}
      >
        Placas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#aprons',
        }}
      >
        Delantales
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#backpacks',
        }}
      >
        Tulas
      </Link>
      <Link
        className={`${appStyles.flexCenter} ${appStyles.link} ${headerStyles.dropdownItem}`}
        onClick={handleCustomClose}
        to={{
          pathname: '/custom',
          hash: '#kerchiefs',
        }}
      >
        Pañoletas
      </Link>
    </Dropdown>,

    <Link onClick={popupStateResponsive.close} key="store" className={`${appStyles.flexLeft} ${appStyles.link} ${headerStyles.navBarItem}`} to="store">
      Taller
    </Link>,
    <Link onClick={popupStateResponsive.close} key="spa" className={`${appStyles.flexLeft} ${appStyles.link} ${headerStyles.navBarItem}`} to="spa">
      SPA
    </Link>,
    <Link onClick={popupStateResponsive.close} key="team" className={`${appStyles.flexLeft} ${appStyles.link} ${headerStyles.navBarItem}`} to="team">
      Nuestro Equipo
    </Link>,
  ];

  return (
    <div className={headerStyles.navBarContainer}>
      <nav className={appStyles.container}>
        {isMobile
          ? (
            <>
              <Dropdown
                popupState={popupStateResponsive}
                title={<FaBars className={headerStyles.faBars} />}
                className={`${appStyles.flexLeft} ${appStyles.verticalAlign}`}
              >
                {navBarItems}
              </Dropdown>
              <div className={`${appStyles.flexLeft} ${appStyles.verticalAlign}`}>
                Peludos Amigos de 4 patas
              </div>
            </>
          ) : (
            <>
              {navBarItems}
              <div className={`${appStyles.flexRight}`}>
                <FaRegClock className={`${appStyles.icons} ${appStyles.verticalAlign}`} />
                L-S 10:00 AM - 6:00 PM
              </div>
            </>
          )}
      </nav>
    </div>

  );
}

export default NavBar;
