import { FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import SocialIcons from '../SocialIcons';

import appStyles from '../../App.module.css';
import bannerStyles from './Banner.module.css';

import logo from '../../static/logos/peludos.png';

function Banner() {
  return (
    <div className={bannerStyles.banner}>
      <div className={bannerStyles.announcementBar}>
        <div className={bannerStyles.announcementContainer}>
          <a
            className={`${appStyles.flexLeft} ${appStyles.whiteLink} ${bannerStyles.bannerItem}`}
            href="https://wa.me/+573118681435"
            rel="noreferrer"
            target="_blank"
          >
            <div className={`${appStyles.icons} ${bannerStyles.bannerItem}`}><FaWhatsapp /></div>
            <div>Escribenos al 3118681435.</div>
          </a>

          <div className={bannerStyles.bannerResponsiveItem}>
            Fabrica de ropa y accesorios para perros y gatos.
          </div>
        </div>
      </div>
      <div className={appStyles.container}>
        <Link className={`${appStyles.link} ${appStyles.flexLeft} ${bannerStyles.bannerItem}`} to="location">
          <div>Ubicación</div>
        </Link>
        <Link className={bannerStyles.bannerLogo} to="/">
          <img src={logo} height="80px" alt="Peludos" />
        </Link>
        <SocialIcons
          containerClassName={`${appStyles.flexRight} ${bannerStyles.bannerItem}`}
          iconClassName={`${appStyles.icons} ${appStyles.link}`}
        />
      </div>
    </div>

  );
}

export default Banner;
