import { GiBed } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item1 from '../../static/images/cobija2.jpeg';
import Item2 from '../../static/images/cobija3.jpeg';
import Item3 from '../../static/images/cobija4.jpeg';
import Item4 from '../../static/images/cobija5.jpeg';
import Item5 from '../../static/images/cobija6.jpeg';
import Item6 from '../../static/images/cobija7.jpeg';
import Item7 from '../../static/images/cobija10.jpg';
import Item8 from '../../static/images/cobija11.jpg';
import Item9 from '../../static/images/cobija12.jpg';
import Item10 from '../../static/images/cobija13.jpg';
import Item11 from '../../static/images/cobija14.jpg';
import Item12 from '../../static/images/cobija15.jpg';
import Item13 from '../../static/images/cobija16.jpg';
import Item14 from '../../static/images/cobija17.jpg';
import Item15 from '../../static/images/cobija18.jpg';

function Blankets() {
  const title = (
    <>
      <GiBed />
      Cobijas.
    </>
  );
  const blankets = [
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
    Item12,
    Item13,
    Item14,
    Item15,
  ];

  return (
    <CustomCarousel title={title} id="blankets" items={blankets} />
  );
}

export default Blankets;
