import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import appStyles from '../App.module.css';
import ItemsPanelArgs from '../interfaces/ItemsPanelArgs';

function ItemsPanel({ children = [] }: ItemsPanelArgs) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { hash } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    const element = hash && document.getElementById(hash.substr(1));

    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - 70;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [hash, isLoaded]);

  return (
    <div className={appStyles.container}>
      {children}
    </div>
  );
}

export default ItemsPanel;
