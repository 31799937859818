import { GiBackpack } from 'react-icons/gi';

import CustomCarousel from './CustomCarousel';

import Item2 from '../../static/images/tula2.jpeg';
import Item3 from '../../static/images/tula3.jpeg';
import Item5 from '../../static/images/tula5.jpg';
import Item6 from '../../static/images/tula6.jpg';
import Item7 from '../../static/images/tula7.jpg';
import Item8 from '../../static/images/tula8.jpg';
import Item9 from '../../static/images/tula9.jpg';
import Item10 from '../../static/images/tula10.jpg';
import Item11 from '../../static/images/tula11.jpg';
import Item12 from '../../static/images/tula12.jpg';
import Item13 from '../../static/images/tula13.jpg';

function Backpacks() {
  const title = (
    <>
      <GiBackpack />
      Tulas.
    </>
  );
  const backpacks = [
    Item2,
    Item3,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
    Item12,
    Item13,
  ];

  return (
    <CustomCarousel title={title} id="backpacks" items={backpacks} />
  );
}

export default Backpacks;
