import { GiBed } from 'react-icons/gi';

import AccessoriesCarousel from './AccessoriesCarousel';

import Item1 from '../../static/images/cama2.jpg';
import Item2 from '../../static/images/cama3.jpg';
import Item3 from '../../static/images/cama4.jpg';
import Item4 from '../../static/images/cama5.jpg';
import Item5 from '../../static/images/cama6.jpg';
import Item6 from '../../static/images/cama7.jpg';
import Item7 from '../../static/images/cama8.jpg';
import Item8 from '../../static/images/cama9.jpg';
import Item9 from '../../static/images/cama10.jpg';

function Beds() {
  const title = (
    <>
      <GiBed />
      Camas.
    </>
  );
  const beds = [Item1, Item2, Item3, Item4, Item5, Item6, Item7, Item8, Item9];

  return (
    <AccessoriesCarousel title={title} id="beds" items={beds} />
  );
}

export default Beds;
