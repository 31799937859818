import { GiMonclerJacket } from 'react-icons/gi';

import ClothesCarousel from './ClothesCarousel';

import Item1 from '../../static/images/saco1.jpeg';
import Item2 from '../../static/images/saco2.jpeg';
import Item3 from '../../static/images/saco3.jpeg';
import Item4 from '../../static/images/saco4.jpeg';
import Item5 from '../../static/images/saco5.jpg';
import Item6 from '../../static/images/saco6.jpg';
import Item7 from '../../static/images/saco7.jpg';
import Item8 from '../../static/images/saco8.jpg';
import Item9 from '../../static/images/saco9.jpg';
import Item10 from '../../static/images/saco10.jpg';
import Item11 from '../../static/images/saco11.jpg';
import Item12 from '../../static/images/saco12.jpg';
import Item13 from '../../static/images/saco13.jpg';

function Sweaters() {
  const title = (
    <>
      <GiMonclerJacket />
      Sacos.
    </>
  );

  const sweaters = [
    Item1,
    Item2,
    Item3,
    Item4,
    Item5,
    Item6,
    Item7,
    Item8,
    Item9,
    Item10,
    Item11,
    Item12,
    Item13,
  ];

  return (
    <ClothesCarousel title={title} id="sweaters" items={sweaters} />
  );
}

export default Sweaters;
