import GridCarousel from '../GridCarousel';

import carousel1 from '../../static/images/local1.jpg';
import carousel2 from '../../static/images/local4.jpg';
import carousel3 from '../../static/images/local7.jpg';

import appStyles from '../../App.module.css';

function Spa() {
  const carouselImages = [
    carousel1,
    carousel2,
    carousel3,
  ];

  return (
    <div className={appStyles.container}>
      <GridCarousel carouselImages={carouselImages} sideImage1={carousel1} sideImage2={carousel2} />
      <h3 className={appStyles.titleSection}>
        SPA
        <b className={appStyles.boldEmptySection} />
      </h3>
      <div className={appStyles.infoContainer}>
        <p>
          <b>El spa de Peludos consta de:</b>
        </p>
        <ul>
          <li>Deslanado (extracción de pelo muerto)</li>
          <li>Corte de uñas</li>
          <li>Limpieza de oidos</li>
          <li>
            Baño de agua tibia con cosmética canina (También te ofrecemos un baño medicado,
            antipulgas o con keratina y tiene un costo adicional de 5.000 a 10.000 pesos
            dependiendo el tamaño de tu peludo.)
          </li>
          <li>Limpieza de dientes y humectación de huellas digitales.🐾</li>
          <li>Perfume si el cliente lo desea.</li>
          <li>Pañoleta de lujo.</li>
        </ul>
        <hr />
        <p>
          <b>Adicionalmente manejamos los siguientes servicios:</b>
        </p>
        <ul>
          <li>Peluquería racial (corte estándar para cada raza)</li>
          <li>Peluquería comercial (cortes exótico, corte oso y corte asiático).</li>
        </ul>
        <h6>
          <b><i>Estos servicios tienen un valor extra dependiendo de la raza de tu peludo.</i></b>
        </h6>
        <hr />
        <h6>
          <b>
            <i>
              Recuerda que debes agendar tu cita con anticipación y cuando recojas a tu peludo
              no olvides pedir tu tarjeta de cliente frecuente y un snack para él.
            </i>
          </b>
        </h6>
      </div>
    </div>
  );
}

export default Spa;
