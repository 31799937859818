import GridCarousel from '../GridCarousel';

import carousel1 from '../../static/images/local1.jpg';
import carousel2 from '../../static/images/local4.jpg';
import carousel3 from '../../static/images/local7.jpg';

import appStyles from '../../App.module.css';

function Store() {
  const carouselImages = [
    carousel1,
    carousel2,
    carousel3,
  ];

  return (
    <div className={appStyles.container}>
      <GridCarousel carouselImages={carouselImages} sideImage1={carousel1} sideImage2={carousel2} />
      <h3 className={appStyles.titleSection}>
        Taller
        <b className={appStyles.boldEmptySection} />
      </h3>
      <div className={appStyles.infoContainer}>
        <p>
          En nuestro taller nos encargamos de traer a la realidad nuestras ideas. Para ello,
          contamos con insumos de calidad y un equipo de trabajo excelente, que puntada a
          puntada, ha llevado a que nuestra marca trascienda fronteras. Llevamos siete años
          trabajando con amor y siempre buscando la calidad en nuestros productos para el confort
          de todos los peludos del mundo y sus propietarios.
        </p>
        <p>
          <b>
            Si tu marca necesita un salto de calidad, ¡te ayudamos a confeccionar tu sueño!
            Contamos con experiencia en trabajo conjunto con marcas referentes en el mercado.
          </b>
        </p>
      </div>
    </div>
  );
}

export default Store;
